@import '_text', '_rwd.scss', '_breakpoints.scss';

.Title {
    @include h5;
}

.ImageContainer {
    height: 90px;
    position: relative;
    margin-bottom: 30px;
    @include rwd($tabletBreakpoint) {
        height: 134px;
    }
}

.Image {
    position: absolute;
    width: 220px;
    @include rwd($tabletBreakpoint) {
        top: -52px;
        width: unset;
    }
    top: -38px;
    left: 50%;
    transform: translateX(-55%);

}

.Description {
    margin-bottom: 30px;
}
