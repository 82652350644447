@import '_form';

.Wrapper {
    margin-bottom: 1rem;
    position: relative;
}

.InputWrapper {
    position: relative;
}

.Icon {
    @include inputDecorativeIcon;
}

.ClearButton {
    @include inputClearButton;
}

.ClearIcon {
    @include inputClearIcon;
}

.PhoneInput {
    @include input;
}
.InputWithError,
.InputWithError:focus {
    border: 2px solid $danger70;
}
.ErrorMessage {
    @include label1;
    color: $danger70;
    display: inline-block;
}

.Label {
    @include inputLabel;
}
