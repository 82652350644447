// SpotOn Nexus design system breakpoints
// https://github.com/SpotOnInc/style-guide-web/blob/main/styles/responsive.scss

$tablet: 768px;
$desktop: 1024px;

@mixin mobile {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin tabletOnly {
    @media (min-width: #{$tablet}) and (max-width: $desktop) {
        @content;
    }
}

@mixin desktopOn {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

// custom breakpoint
// (e.g. @include rwd(650){ border: yellow; }
@mixin rwd($screen) {
    @media (min-width: #{$screen}px) {
        @content;
    }
}
