@import '_text', '_rwd.scss', '_breakpoints.scss';

.Title {
    @include h5;
}

.Image {
    margin-bottom: 30px;
}

.Description {
    margin-bottom: 30px;
}

.Form {
    margin-bottom: 12px;
}

.Modal {
    max-height: none;
    margin-top: 1.5rem;
}
