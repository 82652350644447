@import '_colors.scss';

.DealDateCounter {
  display: inline-flex;
  align-items: center;
  background-color: $base10;
  padding: 0 4px;
  border-radius: 2px;
  font-size: 12px;
  color: $base70;
  svg {
    margin: 0 8px 0 2px;
  }
}