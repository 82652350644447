@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss',
    '_variables.scss';

.Form {
    width: 100%;
}

.DealDuration {
    @include label1;
    color: $base70;
    margin-bottom: 24px;
}
.SubmitButton {
    margin-top: 8px;

    @include rwd($desktopBreakpoint) {
        margin: 0 0 34px;
    }
}

.Error {
    @include label1;

    color: $danger50;
    margin-top: 8px;
}
