@import '_colors', '_text', '_form';

.Button {
    @include button;
}

.PrimaryButton {
    color: $white;
    background-color: $primary50;
    border: 1px solid $primary50;
    width: 100%;

    &:not([disabled]) {
        &:hover {
            background-color: $primary70;
            border: 1px solid $primary70;
        }

        &:focus {
            box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary30;
        }

        &:active,
        &:target {
            background-color: $primary80;
            border: 1px solid $primary80;
        }
    }

    &[disabled],
    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    &:focus,
    &:active,
    &:active:focus {
        outline: none;
        box-shadow: none;
    }

    &::before {
        background-color: $primary50;
    }
}

.Text {
    display: inline-block;
    margin-right: 15px;
}

.Loader {
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid $white;
    border-top-color: $primary50;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
