@import '_colors.scss', '_rwd.scss', '_breakpoints.scss';

.Footer {
  display: flex;
  flex-flow: column;
  padding: 0 32px 24px;
  font-weight: 400;
  font-size: 14px;
  color: $black;
  background-color: $primary10;

  @include rwd($tabletBreakpoint) {
    padding: 0 10% 24px;
    flex-flow: row;
    justify-content: space-between;
  }
}

.FooterTitle {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 18px;
  color: $black;
}

.FooterContent {
  a {
      color: inherit;
  }
  p {
    margin-top: 17px;
    svg {
      margin-right: 4px;
      min-width: 24px;
    }
    display: flex;
    align-items: center;
  }
}


.FooterSpotonSignature {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  svg {
    path {
      fill: $black;
    }
  }
  @include rwd($desktopBreakpoint) {
    margin-right: 15%;
    svg {
      scale: 1.5;
    }
  }
}

.SpotonSignatureLight {
  svg {
    path {
      fill: $white;
    }
  }
}

.SpotonSignatureDark {
  svg {
    path {
      fill: $black;
    }
  }
}

.DarkIcon {
  path {
    fill: $black;
  }
}

.LightIcon {
  path {
    fill: $white;
  }
}