@import 'form', '_colors', '_text';

.Wrapper {
    position: relative;
    margin-bottom: 1rem;
}

.InputWrapper {
    position: relative;
    &.WithError {
        margin-bottom: 15px;
    }
}

.Icon {
    @include inputDecorativeIcon;
}

.ClearButton {
    @include inputClearButton;
}

.ClearIcon {
    @include inputClearIcon;
}

.Input {
    @include input;
}

.InputWithIcon,
.InputWithIcon:focus {
    padding-left: 44px;
}
.InputWithError,
.InputWithError:focus {
    border: 2px solid $danger70;
}
.ErrorMessage {
    @include label1;
    color: $danger70;
    display: inline-block;
}

.Label {
    @include inputLabel;
}
