@import '_colors', '_variables';

.Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    &___blurred {
        background-color: $blurredOverlayBackground;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
    }
}
