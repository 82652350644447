@import '_colors', '_text', '_form';

.Button {
    @include button;
}

.SecondaryButton {
    color: $primary50;
    background-color: transparent;
    border: 1px solid $primary50;
    outline: 0;
    width: 100%;

    &:not([disabled]) {
        &:hover {
            color: $primary70;
            background-color: $primary10;
            border-color: $primary70;
        }

        &:active,
        &:target {
            color: $primary80;
            background-color: $primary20;
            border-color: $primary80;
        }

        &:focus {
            color: $primary50;
            border-color: $primary50;
            box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary30;
        }
    }

    &___disabled,
    &:disabled {
        color: $primary50;
        background-color: $primary10;
        border-color: $primary50;
        cursor: not-allowed;
        opacity: 0.3;
    }

    &:focus,
    &:active,
    &:active:focus {
        outline: none;
        box-shadow: none;
    }

    &::before {
        background-color: $primary10;
    }

    &___danger {
        color: $danger50;
        background-color: transparent;
        border: 1px solid $danger50;

        &:not([disabled]) {
            &:hover {
                color: $danger70;
                background-color: $danger10;
                border: 1px solid $danger70;
            }

            &:active,
            &:target {
                color: $danger80;
                background-color: $danger20;
                border: 1px solid $danger80;
            }

            &:focus {
                box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary30;
            }
        }
    }
}

.Text {
    display: inline-block;
    margin-right: 15px;
}
