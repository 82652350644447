@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
    margin-bottom: 40px;
    @include rwd($tabletBreakpoint) {
        flex-flow: row;
    }
}

.Bold {
    font-weight: bold;
}

.Title {
    margin-bottom: 14px;
}

.FormSection {
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include rwd($tabletBreakpoint) {
        flex-basis: 400px;
    }
}

.Description {
    @include sub1;
    color: $base50;
    margin-bottom: 24px;
}

.MerchantName {
    @include h3;

    @include rwd($desktopBreakpoint) {
        @include h1;
    }

    // design requirement is that fullstop should be square
    .Fullstop {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: $primary50;
    }
}

.Label {
    @include p;
}

.ThumbnailContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include rwd($tabletBreakpoint) {
        align-items: center;
        margin-left: 12%;
        margin-top: 0;
    }

    @include rwd($desktopBreakpoint) {
        align-items: center;
        margin-left: 25%;
        margin-top: 0;
    }
}

.ThumbnailShadow {
    border: 1px solid $base20;
    box-shadow: 0 6px 10px 4px rgba(6, 7, 8, 0.12), 0 2px 3px rgba(6, 7, 8, 0.2);
    border-radius: 8px;
}

.ThumbnailScale {
    @include rwd($desktopBreakpoint) {
        svg {
            scale: 1.6;
        }
    }
}
