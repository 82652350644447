@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Wrapper {
    height: 100%;
    padding-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.LogoEmblem {
    margin-bottom: 40px;
}

.TitleContent {
    @include h3;

    @include rwd($desktopBreakpoint) {
        @include h1;
    }
}

.PromoName {
    margin-bottom: 24px;
}

.Title {
    margin-bottom: 24px;

    @include rwd($desktopBreakpoint) {
        margin-bottom: 50px;
    }
}

.Description {
    @include sub1;
    color: $base50;
    margin-bottom: 24px;
}
