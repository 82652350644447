@import '_text', '_rwd.scss', '_breakpoints.scss';

.Form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
}

.ButtonWrapper {
    display: flex;

    @include rwd($tabletBreakpoint) {
        flex-direction: column;
    }
}

.PrimaryButton {
    margin-bottom: 12px;
    margin-right: 12px;
}

.SecondaryButton {
    margin-bottom: 12px;
}
