@import '_colors', '_text', '_rwd.scss', '_breakpoints.scss';

.Modal {
    @include Text;

    width: 24rem;
    max-height: 80%;
    padding: 1.5rem;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;
    outline: none;
    box-shadow: 0 4px 8px #030d0f29;
    align-self: flex-end;

    @include rwd($tabletBreakpoint) {
        align-self: center;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    background: rgba(53, 63, 94, 0.3);
    overflow-y: auto;
}

.ModalHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1em;
}

.CloseIcon {
    cursor: pointer;
}
