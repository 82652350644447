// https://github.com/SpotOnInc/style-guide-web/blob/main/styles/text.scss
@import '_colors';

$normal-font-sizes: (
    'h1': 3rem,
    // 48px
    'h2': 2.25rem,
    // 36px
    'h3': 1.875rem,
    // 30px
    'h4': 1.5rem,
    // 24px
    'h5': 1.25rem,
    // 20px
    'h6': 1rem,
    // 16px
    'sub1': 1.125rem,
    // 18px
    'sub2': 1rem,
    // 16px
    'p': 0.875rem,
    // 14px
    'bold': 0.875rem,
    // 14px
    'label1': 0.75rem,
    // 12px
    'primarybtn': 1rem,
    // 16px
    'tertiarybtn': 0.875rem,
    // 14px
    'hyperlink': 0.875rem,
    // 14px
);

$large-font-sizes: (
    'h1': 3.75rem,
    // 60px
    'h2': 3rem,
    // 48px
    'h3': 2.25rem,
    // 36px
    'h4': 1.875rem,
    // 30px
    'h5': 1.625rem,
    // 26px
    'h6': 1.25rem,
    // 20px
    'sub1': 1.5rem,
    // 24px
    'sub2': 1.25rem,
    // 20px
    'p': 1.125rem,
    // 18px
    'bold': 1.125rem,
    // 18px
    'label1': 1.125rem,
    // 18px
    'primarybtn': 1.375rem,
    // 22px
    'tertiarybtn': 1.25rem,
    // 20px
    'hyperlink': 1.125rem,
    // 20px
);

$normal-line-heights: (
    'h1': 4.5rem,
    // 72px
    'h2': 3.375rem,
    // 54px
    'h3': 2.875rem,
    // 46px
    'h4': 2.25rem,
    // 36px
    'h5': 1.875rem,
    // 30px
    'h6': 1.375rem,
    // 22px
    'sub1': 1.75rem,
    // 28px
    'sub2': 1.375rem,
    // 22px
    'p': 1.375rem,
    // 22px
    'bold': 1.375rem,
    // 22px
    'label1': 1.25rem,
    // 20px
    'primarybtn': 1.5rem,
    // 24px
    'tertiarybtn': 1.375rem,
    // 22px
    'hyperlink': 1.375rem,
    // 22px
);

$large-line-heights: (
    'h1': 5.625rem,
    // 90px
    'h2': 4.5rem,
    // 72px
    'h3': 3.375rem,
    // 54px
    'h4': 2.875rem,
    // 46px
    'h5': 2.5rem,
    // 40px
    'h6': 2.5rem,
    // 40px
    'sub1': 2.25rem,
    // 36px
    'sub2': 1.875rem,
    // 30px
    'p': 1.75rem,
    // 28px
    'bold': 1.75rem,
    // 28px
    'label1': 1.5rem,
    // 24px
    'primarybtn': 2.125rem,
    // 34px
    'tertiarybtn': 1.875rem,
    // 30px
    'hyperlink': 1.75rem,
    // 28px
);

@function calculate-font-size($variant, $header) {
    @if $variant == 'large' {
        @return map-get($large-font-sizes, $header);
    }

    @return map-get($normal-font-sizes, $header);
}

@function calculate-line-height($variant, $header) {
    @if $variant == 'large' {
        @return map-get($large-line-heights, $header);
    }

    @return map-get($normal-line-heights, $header);
}

// font weights
$text-font-weight-heavy: 700;
$text-font-weight-bold: 700;
$text-font-weight-semibold: 600;
$text-font-weight-medium: 500;
$text-font-weight-regular: 400;

@mixin Text {
    display: block;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin h1($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-heavy;
    font-size: calculate-font-size($variant, 'h1');
    line-height: calculate-line-height($variant, 'h1');
}

@mixin h2($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-heavy;
    font-size: calculate-font-size($variant, 'h2');
    line-height: calculate-line-height($variant, 'h2');
}

@mixin h3($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'h3');
    line-height: calculate-line-height($variant, 'h3');
}

@mixin h4($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'h4');
    line-height: calculate-line-height($variant, 'h4');
}

@mixin h5($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'h5');
    line-height: calculate-line-height($variant, 'h5');
}

@mixin h6($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'h6');
    line-height: calculate-line-height($variant, 'h6');
}

@mixin sub1($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-medium;
    font-size: calculate-font-size($variant, 'sub1');
    line-height: calculate-line-height($variant, 'sub1');
}

@mixin sub2($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-medium;
    font-size: calculate-font-size($variant, 'sub2');
    line-height: calculate-line-height($variant, 'sub2');
}

@mixin p($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-regular;
    font-size: calculate-font-size($variant, 'p');
    line-height: calculate-line-height($variant, 'p');
}

@mixin bold {
    @include Text;

    display: inline;
    font-weight: $text-font-weight-semibold;
    letter-spacing: 0.01rem;
}

@mixin boldParagraph($variant: 'regular') {
    @include Text;

    display: inline;
    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'bold');
    line-height: calculate-line-height($variant, 'bold');
    letter-spacing: 0.01rem;
}

@mixin label1($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-regular;
    font-size: calculate-font-size($variant, 'label1');
    line-height: calculate-line-height($variant, 'label1');
}

@mixin primarybtn($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'primarybtn');
    line-height: calculate-line-height($variant, 'primarybtn');
    letter-spacing: 0.01rem;
}

@mixin tertiarybtn($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-semibold;
    font-size: calculate-font-size($variant, 'tertiarybtn');
    line-height: calculate-line-height($variant, 'tertiarybtn');
    letter-spacing: 0.01rem;
}

@mixin hyperlink($variant: 'regular') {
    @include Text;

    font-weight: $text-font-weight-medium;
    font-size: calculate-font-size($variant, 'hyperlink');
    line-height: calculate-line-height($variant, 'hyperlink');
    letter-spacing: 0.01rem;
    text-decoration: underline;
}

@mixin number {
    @include Text;

    display: inline;
    letter-spacing: 0.04rem;
}
