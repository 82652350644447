@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Icon {
  position: relative;
  bottom: -6px;
  width: 22px;
  height: 22px;
}

.Title {
  @include label1;
  cursor: pointer;
  font-size: 14px;
  margin-top: 16px;
}

.List {
  width: 100%;
  margin-top: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.LocationVisible {
  opacity: 1;
}

.Name {
  @include label1;
  border-radius: 50px;
  background-color: $base20;
  display: inline-block;
  padding: 0 6px;
  margin: 0 12px 10px 0;
  color: $black;
}