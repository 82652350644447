@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
    margin-bottom: 40px;
    @include rwd($tabletBreakpoint) {
        flex-flow: row;
    }
}

.FormSection {
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include rwd($tabletBreakpoint) {
        flex-basis: 400px;
    }
}

.DealTimeContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.Title {
    margin-bottom: 14px;
}

.Description {
    @include sub1;
    color: $base50;
    margin-bottom: 24px;
}

.TermsAndPolicy {
    @include label1;
    margin-top: 16px;
    color: $base70;
}

.CouponInfo {
    @include label1;
    color: $base50;
    margin-top: 8px;
}

.ThumbnailContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include rwd($tabletBreakpoint) {
        align-items: center;
        margin-left: 12%;
        margin-top: 0;
    }

    @include rwd($desktopBreakpoint) {
        align-items: center;
        margin-left: 25%;
        margin-top: 0;
    }
}

.ThumbnailShadow {
    border: 1px solid $base20;
    box-shadow: 0 6px 10px 4px rgba(6, 7, 8, 0.12), 0 2px 3px rgba(6, 7, 8, 0.2);
    border-radius: 8px;
}

.ThumbnailScale {
    @include rwd($desktopBreakpoint) {
        svg {
            scale: 1.6;
        }
    }
}
.DiscountDescriptionWrapper {
    color: $base90;
    margin: 30px 0 40px 0;
}
.DiscountDescription {
    font-size: 30px;
}
.DiscountCondition {
    font-size: 18px;
}
