@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
    margin-bottom: 40px;
    @include rwd($tabletBreakpoint) {
        flex-flow: row;
        justify-content: space-between;
        margin-top: 20px;
    }
}

.FormSection {
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include rwd($tabletBreakpoint) {
        flex-basis: 600px;
    }
}

.Content {
    max-width: 420px;
}

.Title {
    @include h3;
    color: $black;

    @include rwd($tabletBreakpoint) {
        @include h1;
        color: $black;
    }

    margin-bottom: 14px;
}

.Subtitle {
    @include h4;

    @include rwd($tabletBreakpoint) {
        @include h3;
        color: $base90;
    }

    color: $base90;
}

.Description {
    @include sub1;
    color: $base50;
    margin: 24px 0;
}

.TermsAndPolicy {
    @include label1;
    margin-top: 16px;
    color: $base70;
}

.ThumbnailContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include rwd($tabletBreakpoint) {
        align-items: center;
        margin-left: 12%;
        margin-top: 0;
    }

    @include rwd($desktopBreakpoint) {
        align-items: center;
        margin-left: 25%;
        margin-top: 0;
    }
}

.ThumbnailShadow {
    border: 1px solid $base20;
    box-shadow: 0 6px 10px 4px rgba(6, 7, 8, 0.12), 0 2px 3px rgba(6, 7, 8, 0.2);
    border-radius: 8px;
}

.ThumbnailScale {
    @include rwd($desktopBreakpoint) {
        svg {
            scale: 1.6;
        }
    }
}
