@import '_text', '_colors';

@mixin button {
    position: relative;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto;
    padding: 10px 24px;
    font-weight: $text-font-weight-semibold;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    inline-size: fit-content;
}

// phone and email inputs share the same styles

@mixin input {
    @include p;

    box-sizing: border-box; // prevents border jumping on focus/error with 2px border width
    width: 100%;
    height: 2.75rem; // prevents border jumping on focus/error with 2px border width
    padding: 0.6875rem 1rem 0.6875rem; // 11px 32px 11px 16px
    font-size: 0.85rem;
    border: 1px solid $base50;
    border-radius: 0.25rem;
    outline: 0;

    &::placeholder {
        color: $base30;
    }

    &:hover {
        border: 1px solid $base70;

        + .InputIcon {
            color: $base70;
        }
    }

    &:focus {
        border: 2px solid $primary50;

        + .InputIcon {
            color: $primary50;
        }
    }

    &:active {
        border: 1px solid $black;

        + .InputIcon {
            color: $black;
        }
    }

    // since we have a custom clear icon, we want to hide default browser buttons
    // clears the 'X' from IE
    &::-ms-clear,
    &[type='search']::-ms-clear,
    &[type='search']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    // clears the 'X' from Chrome
    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        display: none;
        // This is only needed for old chrome
        // stylelint-disable-next-line plugin/no-unsupported-browser-features
        -webkit-appearance: none;
    }
}

@mixin inputClearButton {
    position: absolute;
    border: none;
    background: none;
    appearance: none;
    right: 0;
    top: 0;
    bottom: 0;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@mixin inputClearIcon {
    height: 17px;
    user-events: none;
}

@mixin inputDecorativeIcon {
    position: absolute;
    left: 19px;
    top: 14px;
    height: 17px;
}

@mixin inputLabel {
    font-size: 12px;
    line-height: 20px;
    user-select: none;
}

@mixin focusShadow {
    box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary30;
}
