@import 'form', '_colors';

.InputLabel {
    font-size: 12px;
    line-height: 20px;
}

.RequiredIndicator {
    color: $danger70;
}

.ErrorMessage {
    @include label1;
    color: $danger70;
    display: inline-block;
}