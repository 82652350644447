@import '_form';

.Wrapper {
    margin-bottom: 1.5rem;
}

.InputWrapper {
    position: relative;
}

.Icon {
    @include inputDecorativeIcon;
}

.ClearButton {
    @include inputClearButton;
}

.ClearIcon {
    @include inputClearIcon;
}

.DateInput {
    @include input;
    &::placeholder {
        color: red;
    }

    -webkit-appearance: none;
    background-color: white;
}

.Label {
    @include inputLabel;
}
