@import '_colors.scss', '_rwd.scss', '_breakpoints.scss';

.Layout {
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
}

.Wrapper {
    margin: 0 5%;
    flex: 1;

    @include rwd($desktopBreakpoint) {
        margin: 0 15%;
    }
}



.Logo {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    @include rwd($tabletBreakpoint) {
        justify-content: flex-start;
    }
    svg {
        @include rwd($tabletBreakpoint) {
            transform-origin: left center;
        }

        transform: scale(2);
    }
}

.MerchantLogo {
    max-width: 240px;
    max-height: 80px;
}